import React from "react";

function TermsOfUse() {
  return (
    <div className="terms-container">
      <h1 className="policy-h1">Terms of Service</h1>
      <p className="policy-p">Last Updated: Feb. 5th 2025</p>
      
      <div className="terms-section">
        <h2 className="policy-h2">1. Acceptance of Terms</h2>
        <p className="policy-p">By using Commit to Memory at <a href="https://committomemory.com">https://committomemory.com</a>, you agree to these Terms.</p>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">2. Intellectual Property</h2>
        <p className="policy-p">All content belongs to Commit to Memory and is protected under copyright laws.</p>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">3. User-Generated Content</h2>
        <p className="policy-p">By submitting content, you grant us a license to use it.</p>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">4. Limitation of Liability</h2>
        <p className="policy-p">We are not responsible for any damage caused by using our Website.</p>
      </div>

      <footer className="policy-footer">
        <p>&copy; 2025 Commit to Memory. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsOfUse;
