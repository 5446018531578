import React from "react";

function PrivacyPolicy() {
  return (
    <div className="terms-container">
      <h1 className="policy-h1">Privacy Policy</h1>
      <p className="policy-p">Last Updated: Feb. 5th 2025</p>
      
      <div className="terms-section">
        <h2 className="policy-h2">1. Introduction</h2>
        <p className="policy-p">Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use Commit to Memory, including when you use our contact form powered by EmailJS and make donations through GiveButter.</p>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">2. Information We Collect</h2>
        <p className="policy-p">We may collect the following types of information when you use our services:</p>
        <ul className="policy-ul">
          <li><strong>Personal Information:</strong> Name and email address that you provide when using our contact form.</li>
          <li><strong>Payment Information:</strong> When making a donation, we may collect payment information (e.g., credit card details) via GiveButter. We do not store sensitive payment information directly.</li>
          <li><strong>Usage Data:</strong> Information about how you interact with our site, including the pages you visit and actions you take.</li>
          <li><strong>Cookies:</strong> We use cookies to enhance user experience, remember your preferences, and analyze site traffic.</li>
        </ul>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">3. How We Use Your Information</h2>
        <p className="policy-p">We use the information we collect to:</p>
        <ul className="policy-ul">
          <li>Provide and improve our services, including responding to inquiries submitted through the contact form.</li>
          <li>Process and acknowledge donations, including sending donation receipts via GiveButter.</li>
          <li>Communicate with you about updates, promotions, and other relevant news.</li>
          <li>Personalize your experience on our website.</li>
        </ul>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">4. Third-Party Services</h2>
        <p className="policy-p">We use third-party services to manage the contact form and process donations. These third parties may collect, store, and use your personal and payment information according to their own privacy policies. We encourage you to review their privacy policies:</p>
        <ul className="policy-ul">
          <li><strong>EmailJS:</strong> The contact form is powered by EmailJS. Your submitted data may be stored on their servers. Please refer to <a href="https://www.emailjs.com/privacy-policy/" target="_blank" rel="noopener noreferrer">EmailJS Privacy Policy</a> for more details.</li>
          <li><strong>GiveButter:</strong> Donations are processed by GiveButter. They may collect payment information, and we do not store sensitive payment data. Please review <a href="https://www.givebutter.com/privacy-policy" target="_blank" rel="noopener noreferrer">GiveButter's Privacy Policy</a> for more information on how they handle your payment details.</li>
        </ul>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">5. How We Protect Your Information</h2>
        <p className="policy-p">We implement various security measures, including encryption and secure storage practices, to ensure the protection of your personal information. However, since third-party services are used for certain features (e.g., donations, contact form), we are not responsible for the security practices of these third parties.</p>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">6. Your Rights</h2>
        <p className="policy-p">You have the right to access, correct, or delete your personal information. You can also withdraw consent for the collection of certain data at any time. If you wish to exercise any of these rights, please contact us through our contact form or the provided email address.</p>
      </div>

      <div className="terms-section">
        <h2 className="policy-h2">7. Changes to This Privacy Policy</h2>
        <p className="policy-p">We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated date. Please review this policy periodically to stay informed about how we are protecting your data.</p>
      </div>

      <footer className="policy-footer">
        <p>&copy; 2025 Commit to Memory. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
